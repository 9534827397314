<template>
<h1 class="text-center">InertiaPlugin</h1>
</template>

<script>
export default {
  name: "InertiaPlugin"
}
</script>

<style scoped>

</style>